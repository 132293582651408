'use client';
import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  Heading,
  PillText,
  Tooltip,
  Divider,
  VisuallyHidden,
} from '@web/atoms';
import { useReporter } from '../../reporter';
import BoxImage from '../../images/mx-ollie-box.png';
import EmptyAddBox from '../../images/empty-add-box.png';
import {
  HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
  useExperiment,
} from '../../experiments';
import { addonImageMap, recipeImageMap } from './constants';
import {
  userClickEditFreshRecipe,
  userClickEditBakedRecipe,
  userClickEditAddons,
  userClickChangeDeliveryDate,
  userClicksPlusSignInNextBox,
} from './events';
import {
  Container,
  DateInfo,
  ArrivalText,
  IncludedText,
  SubText,
  ProductInfoAnchor,
  RecipeCount,
  EditAnchor,
  ChangeNotAllowedText,
  ChangeNotAllowedTip,
  Contents,
  RecipeImageContainer,
  RecipeImage,
  AddonCount,
  AddonCountWithImage,
  TrackingLinkContainer,
  NoteText,
} from './styles.js';
import { getAddonsConfig, getSubtext } from './functions';

export const NextBox = ({
  arrivalDate,
  lockInDate,
  isLockedIn,
  cadence,
  recipes,
  latestOrderAddons,
  subscriptionAddons,
  showAddons,
  editFreshHref,
  editBakedHref,
  editAddonsHref = '',
  editDeliveryDateHref,
  editDeliveryDateText,
  headline,
  totalPacks,
  totalBags,
  trackingUrl,
}) => {
  const reporter = useReporter();

  const { value: hideBaked } = useExperiment(
    HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
    true,
  );

  const { addons, noteText } = getAddonsConfig({
    latestOrderAddons,
    subscriptionAddons,
    isLockedIn,
  });

  const hasFresh = Boolean(recipes.fresh.length);
  const hasBaked = Boolean(recipes.dry.length);
  const hasAddons = Boolean(addons.length);

  const subText = getSubtext({
    hasFresh,
    hasBaked,
    cadence,
    totalBags,
    totalPacks,
  });

  const onEditFreshRecipeHandler = () => {
    reporter.tag(userClickEditFreshRecipe());
  };
  const onEditBakedRecipeHandler = () => {
    reporter.tag(userClickEditBakedRecipe());
  };
  const onEditAddonsHandler = () => {
    reporter.tag(userClickEditAddons());
  };

  const onChangeDeliveryDateHandler = () => {
    reporter.tag(userClickChangeDeliveryDate(isLockedIn));
  };

  const onViewExtrasHandler = () => {
    const prefixedReporter = reporter.withPrefix('');
    prefixedReporter.tag(userClicksPlusSignInNextBox());
  };
  return (
    <>
      <Heading
        headingText={headline}
        headingLevel="h2"
        typography={{ desktop: 'heading2', mobile: 'heading1' }}
      />
      <Container>
        <DateInfo>
          <ResponsiveImage
            src={BoxImage}
            alt="ollie box"
            height={120}
            width={120}
          />
          <ArrivalText>Next box arrives by {arrivalDate}</ArrivalText>
          {!isLockedIn ? (
            <>
              <SubText>You can make changes until {lockInDate}</SubText>
              <EditAnchor
                href={editDeliveryDateHref}
                onClick={onChangeDeliveryDateHandler}
              >
                {editDeliveryDateText}
              </EditAnchor>
            </>
          ) : (
            <>
              <Tooltip
                placement="top"
                trigger={props => (
                  <ChangeNotAllowedText {...props}>
                    You can no longer make changes to this box, shipment is in
                    progress.
                  </ChangeNotAllowedText>
                )}
              >
                <ChangeNotAllowedTip>
                  If you have questions about this box, please contact our
                  Canine Care team at{' '}
                  <a href="mailto:hello@myollie.com">hello@myollie.com</a>
                </ChangeNotAllowedTip>
              </Tooltip>
            </>
          )}
          {trackingUrl && (
            <TrackingLinkContainer>
              <LinkButton
                variant="secondary"
                href={trackingUrl}
                percentWidthMobile={90}
              >
                Track your box
              </LinkButton>
            </TrackingLinkContainer>
          )}
        </DateInfo>
        <Divider />
        <Contents>
          <IncludedText>What's included in this box</IncludedText>
          <SubText>{subText}</SubText>
          <ProductInfoAnchor href="/ingredients" target="_blank">
            View product information
          </ProductInfoAnchor>
          {hasFresh && (
            <>
              <RecipeCount>
                Fresh Recipes ({recipes.fresh.length})
                {!isLockedIn ? (
                  <EditAnchor
                    href={editFreshHref}
                    onClick={onEditFreshRecipeHandler}
                  >
                    Edit
                    <VisuallyHidden>Fresh Recipes</VisuallyHidden>
                  </EditAnchor>
                ) : null}
              </RecipeCount>
              <RecipeImageContainer>
                {recipes.fresh.map((recipe, index) => (
                  <RecipeImage key={index}>
                    <ResponsiveImage
                      src={recipeImageMap.fresh[recipe]}
                      alt={`${recipe} pack`}
                      height={72}
                      width={111}
                    />
                  </RecipeImage>
                ))}
              </RecipeImageContainer>
            </>
          )}
          {hasBaked && (
            <>
              <RecipeCount>
                Baked Recipes ({recipes.dry.length})
                {!isLockedIn && !hideBaked ? (
                  <EditAnchor
                    href={editBakedHref}
                    onClick={onEditBakedRecipeHandler}
                  >
                    Edit
                    <VisuallyHidden>Baked Recipes</VisuallyHidden>
                  </EditAnchor>
                ) : null}
              </RecipeCount>
              <RecipeImageContainer>
                {recipes.dry.map((recipe, index) => (
                  <RecipeImage key={index}>
                    <ResponsiveImage
                      src={recipeImageMap.dry[recipe]}
                      alt={`${recipe} pack`}
                      height={104}
                      width={100}
                    />
                  </RecipeImage>
                ))}
              </RecipeImageContainer>
            </>
          )}
          {showAddons && (
            <>
              {hasAddons && (
                <RecipeCount>
                  Subscription Extras {hasAddons && `(${addons.length})`}
                  {hasAddons ? (
                    !isLockedIn && (
                      <EditAnchor
                        href={editAddonsHref}
                        onClick={onEditAddonsHandler}
                      >
                        Edit
                        <VisuallyHidden>Extras</VisuallyHidden>
                      </EditAnchor>
                    )
                  ) : (
                    <PillText text="New!" variant="kale"></PillText>
                  )}
                </RecipeCount>
              )}
              {hasAddons && (
                <RecipeImageContainer>
                  {addons.map((addon, index) => (
                    <RecipeImage key={index}>
                      <AddonCountWithImage>
                        <AddonCount>{addon.quantity}</AddonCount>
                        <ResponsiveImage
                          src={addonImageMap[addon.sku].src}
                          alt={`${addon.sku} pack`}
                          height={104}
                          width={100}
                        />
                      </AddonCountWithImage>
                    </RecipeImage>
                  ))}
                  {addons.length === 1 && (
                    <Link
                      href={editAddonsHref}
                      onClick={onViewExtrasHandler}
                      passHref
                    >
                      <ResponsiveImage
                        src={EmptyAddBox.src}
                        alt="Empty Addon Box"
                        height={104}
                        width={100}
                      />
                    </Link>
                  )}
                </RecipeImageContainer>
              )}
              {noteText && (
                <NoteText>
                  <strong>Note: </strong>
                  {noteText}
                </NoteText>
              )}
            </>
          )}
        </Contents>
      </Container>
    </>
  );
};

NextBox.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  lockInDate: PropTypes.string,
  isLockedIn: PropTypes.bool.isRequired,
  cadence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  recipes: PropTypes.shape({
    fresh: PropTypes.arrayOf(
      PropTypes.oneOf(['beef', 'chicken', 'lamb', 'turkey']),
    ),
    dry: PropTypes.arrayOf(
      PropTypes.oneOf(['beef', 'chicken', 'lamb', 'turkey']),
    ),
  }).isRequired,
  latestOrderAddons: PropTypes.array.isRequired,
  subscriptionAddons: PropTypes.array.isRequired,
  editFreshHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  editBakedHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  editAddonsHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  editDeliveryDateHref: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  editDeliveryDateText: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  totalPacks: PropTypes.number.isRequired,
  totalBags: PropTypes.number.isRequired,
  showAddons: PropTypes.bool.isRequired,
  trackingUrl: PropTypes.string,
};
